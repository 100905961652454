import React, { useContext } from "react";
import CompanyDetails from "../context/companyDetailsContext";

function Footer() {
  const companyDetails = useContext(CompanyDetails);

  return (
    <footer className="footer-style-1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="footer-social text-center text-lg-left ">
              <ul className="list-unstyled">
                <li>
                  <a
                    className="wow fadeInUp"
                    target="blank"
                    href={companyDetails.comdetail.linkdtl.facebook}
                  >
                    <i aria-hidden="true" className="fa fa-facebook-f"></i>
                  </a>
                </li>

                <li>
                  <a
                    className="wow fadeInDown"
                    target="blank"
                    href={companyDetails.comdetail.linkdtl.linkedin}
                  >
                    <i aria-hidden="true" className="fa fa-linkedin-in"></i>
                  </a>
                </li>

                <li>
                  <a
                    className="wow fadeInUp"
                    target="blank"
                    href={companyDetails.comdetail.linkdtl.instagram}
                  >
                    <i aria-hidden="true" className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 text-center text-lg-right">
            <p className="company-about fadeIn">
              {companyDetails.comdetail.textdtl.copytext}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
