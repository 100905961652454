import React, { useContext } from "react";
import CompanyDetails from "../../context/companyDetailsContext";

function Team() {
  const companyDetails = useContext(CompanyDetails);

  return (
    <section className="team-sec position-relative" id="team-sec">
      <div className="left-overlay"></div>

      <div className="container">
        <div className="row inner-team-sec padding-top padding-bottom">
          <div className="col-12 col-lg-4 text-center text-lg-left">
            <div className="team-detail wow fadeInLeft">
              <h4 className="heading">
                WE HAVE <span>AMAZING TEAM</span>
              </h4>
            </div>
          </div>

          <div className="col-12 col-lg-8">
            <div className="team-area wow fadeInRight">
              <div className="row no-gutters team-carousel owl-carousel owl-theme">
                <div className="item text-center">
                  <div className="team-box">
                    <div className="img-holder position-relative">
                      <img src="architect-studio/img/team1.jpg" alt="team" />

                      <div className="overlay d-flex justify-content-center align-items-center">
                        <ul className="team-social">
                          <li>
                            <a
                              className="facebook-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.facebook}
                            >
                              <i
                                className="fa fa-facebook-f"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>

                          <li>
                            <a
                              className="linkedin-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.linkedin}
                            >
                              <i
                                aria-hidden="true"
                                className="fa fa-linkedin-in"
                              ></i>
                            </a>
                          </li>

                          <li>
                            <a
                              className="instagram-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.instagram}
                            >
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="team-info">
                      <h4 className="team-name">Eden Wooderburg</h4>

                      <p className="team-designation">Creative Head</p>
                    </div>
                  </div>
                </div>

                <div className="item text-center">
                  <div className="team-box">
                    <div className="img-holder position-relative">
                      <img src="architect-studio/img/team2.jpg" alt="teams" />

                      <div className="overlay d-flex justify-content-center align-items-center">
                        <ul className="team-social">
                          <li>
                            <a
                              className="facebook-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.facebook}
                            >
                              <i
                                className="fa fa-facebook-f"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>

                          <li>
                            <a
                              className="linkedin-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.linkedin}
                            >
                              <i
                                aria-hidden="true"
                                className="fa fa-linkedin-in"
                              ></i>
                            </a>
                          </li>

                          <li>
                            <a
                              className="instagram-text-hvr"
                              target="blank"
                              href={companyDetails.comdetail.linkdtl.instagram}
                            >
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="team-info">
                      <h4 className="team-name">Angelo Walking</h4>

                      <p className="team-designation">Design Lead</p>
                    </div>
                  </div>
                </div>
              </div>

              <button className="team-nav team-prev" id="team-prev">
                <i className="fa fa-angle-left"></i>
              </button>

              <button className="team-nav team-next" id="team-next">
                <i className="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Team);
