import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import PreLoader from "./components/PreLoader";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";
import Error from "./components/Error";
import CompanyDetails from "./context/companyDetails";
import AboutPage from "./components/AboutPage";
import PortfolioPage from "./components/PortfolioPage";
import TestemonialsPage from "./components/TestemonialsPage";
import ContactPage from "./components/ContactPage";
import ServicesPage from "./components/ServicesPage";
import SubService from "./components/SubService";
import Career from "./components/Career";
import { useEffect } from "react";

function iOS() {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
}

function App() {
  const callFun = window["onUrlChange"];
  const callFunIos = window["initCubePort"];

  useEffect(() => {
    if (iOS()) {
      callFunIos();
    }
    callFun();
  }, []);
  return (
    <>
      <CompanyDetails>
        <PreLoader />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/testimonials" element={<TestemonialsPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route
            path="/custom-logo"
            element={
              <SubService
                name="CUSTOM LOGO DESIGN"
                pict="architect-studio/img/CUSTOM LOGO DESIGN.png"
                para="In this age of 
                technology as online business is becoming more and more popular, businesses
                need to ensure they have a secure and easy system to be able to charge customers
                online. Our service provides you with a merchant account with the ability of accepting
                online payments through payment gateway."
              />
            }
          />

          <Route
            path="/web-development"
            element={
              <SubService
                name="WEB DEVELOPMENT"
                pict="architect-studio/img/website-development-spark.jpg"
                para="We believe businesses should not have to worry about maintaining their websites as you already have so many other priorities and tasks. With today's rapidly changing technology it is not always feasible for business owners to keep up with the Internet technology. Our support team will ensure that your business website is up to date, and protected and ready to evolve with most latest market trends. It can be regular updates, security, backup, support services."
              />
            }
          />

          <Route
            path="/app-development"
            element={
              <SubService
                name="APP DEVELOPMENT"
                pict="architect-studio/img/app-development.jpg"
                para="The number of people who own Android devices is growing year over year. You certainly want to offer your unique solution to this wide user base! We develop Android apps for various devices using the Java and Kotlin programming languages and Google’s own development tools and guidelines. We offer our services on reasonable prices we have also IOS developers."
              />
            }
          />

          <Route
            path="/uiux-design"
            element={
              <SubService
                name="UI/UX DESIGNING"
                pict="architect-studio/img/ui-ux-spark.jpg"
                para="We are a design company offering engaging and enchanting UI & UX design services directed towards building a professional look of your web or mobile designs. From analyzing your industry to understanding your business we craft designs which perfectly correlate with your brand image, keeping the user engaged with the platform and thus providing an exceptional user experience.
                SATISFIED"
              />
            }
          />

          <Route
            path="/api-implementation"
            element={
              <SubService
                name="API IMPLEMENTATION"
                pict="architect-studio/img/api-spark.png"
                para="We offer custom API development and testing services to meet a wide range of business requirements and security protocols. API development refers to designing an Application Programming Interface (API) for both web servers and browsers. Based on client-side and server-side API, its design varies and it defines a clear set of HTTP requests along with responses to be shared. Spark is a leading SPARK development company offering quality and recur web API services. Our developers are proficient in developing well-documented, reliable, and easy-to-consume APIs. With a competent and skilled team of developers backed by extensive experience, we have served a plethora of global clients for more than a decade, providing them with affordable and flexible solutions."
              />
            }
          />

          <Route
            path="/digital-marketing"
            element={
              <SubService
                name="DIGITAL MARKETING"
                pict="architect-studio/img/digital-marketing-spark.png"
                para="We provide professional digital marketing services to give you the best digital exposure you deserve. We strongly believe that going digital is the way forward, for any business now, and we try to reflect our beliefs through dedication in our services. We make your brand available to your potential customers at the right time and on the right screen. Our winning solutions and experience have helped many of our clients to interact and engage with their customers in a new better way."
              />
            }
          />

          <Route
            path="/content-management"
            element={
              <SubService
                name="CONTENT MANAGEMENT SYSTEM"
                pict="architect-studio/img/spark-cms.jpg"
                para="Spark believes in developing content management systems that are simple, powerful and scalable. We not only just offer technologies such as WordPress or Joomla but we also offer the right solution based on your business, the type of updates needed and what your customers need."
              />
            }
          />

          <Route
            path="/print-media-design"
            element={
              <SubService
                name="PRINT MEDIA DESIGN"
                pict="architect-studio/img/print-media-spark.png"
                para="Ranging from your company's promotional material, brochures, business stationery and so much more, the services are endless. Our design team offers nothing than the best print services to business who understand the value of professional design solutions."
              />
            }
          />

          <Route
            path="/web-design-redesign"
            element={
              <SubService
                name="WEB DESIGN REDESIGN"
                pict="architect-studio/img/webspark1.jpg"
                para="The logo of your company is the most essential tool and it sets you apart from all other companies. It demonstrates your purpose, reliability, professionalism and so much more. It needs to be the most eye-catching and have the biggest impact for your business. Even though logo is just one aspect of your company, having it done right and ensuring consistency is vital to your company’s success."
              />
            }
          />

          <Route
            path="/graphic-designing"
            element={
              <SubService
                name="GRAPHIC DESIGNING"
                pict="architect-studio/img/graphic_design-spark.jpg"
                para="Our experienced design team has the imagination and vision to deliver innovative print and digital design from concept to the finished product. Keeping a strong relationship with our clients is very important to us and we derive to help you arrive at the perfect solution."
              />
            }
          />

          <Route
            path="/seo-content-writing"
            element={
              <SubService
                name="SEO & CONTENT WRITING"
                pict="architect-studio/img/seo-content-writing.jpg"
                para="SEO content writing is process of creating search engine optimized content that’s relevant for the target audience and addresses the search queries in question. Its primary aim is to satisfy search engine algorithms and rank in SERPs to drive traffic. We are giving service of search engin optimization and content writing on cheap price."
              />
            }
          />

          <Route
            path="/mobile-application"
            element={
              <SubService
                name="MOBILE APPLICATION  DEVELOPMENT"
                pict="architect-studio/img/spark-app.png"
                para="We create enterprise and consumer-level mobile applications that provide an engaging and sophisticated mobile experience on any device and platform you prefer at affordable rates."
              />
            }
          />

          <Route
            path="/website-maintenance"
            element={
              <SubService
                name="WEBSITE MAINTENANCE"
                pict="architect-studio/img/maintain_spark.jpg"
                para="Maintenance is important for any website. Once the website is set up, it needs updating, monitoring, backups, and technical support. Spark offer professional and affordable website maintenance service with experienced gentles and masters of Gexton who makes time to the time change to your website when you needed. We allow you to keep your website up-to-date, secure and monitored at a fragment of the cost. Spark is the best service provider for small businesses, entrepreneurs, freelancers, and organizations. As a website maintenance company, we regularly see the instance in which small businesses owners insist their business website designed, developed, and pushed live."
              />
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </CompanyDetails>

      <span className="scroll-top-arrow">
        <i className="fa fa-angle-up"></i>
      </span>
      <div className="aimated-cursor">
        <div className="cursor">
          <div className="cursor-loader"></div>
        </div>
      </div>
    </>
  );
}

export default App;
