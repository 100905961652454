import { Link } from "react-router-dom";
import Contact from "./home_Page/Contact";
import Sponsers from "./home_Page/Sponsers";
import Testemonials from "./home_Page/Testemonials";
import PageTitke from "./PageTitke";

export default function AboutPage() {
  return (
    <>
      <PageTitke tiltext="About Us" />

      <section
        className="wpo-mission-vission-section wow fadeInUp"
        data-wow-delay=".8s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-mission-item">
                <div className="wpo-mission-text">
                  <h2>Our Mission</h2>
                  <p>
                    Our mission is to provide affordable, premium-quality Web
                    Development services that meet the business needs of our
                    customers and that ALWAYS exceed their expectations. We
                    pride ourselves on always putting our client's needs first,
                    understanding that from the basic design of the project, to
                    completion.
                  </p>
                  <h5>
                    we stay with our clients every step of the way and after the
                    project is completed and delivered.
                  </h5>
                </div>
                <div className="shape-1"></div>
                <div className="shape-2">
                  <img
                    src="architect-studio/img/mission-shape.png"
                    alt="mission shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-vission-item">
                <div className="wpo-vission-text">
                  <h2>Our Vission</h2>
                  <h5>
                    At Sparks, we are guided by a set of core values that define
                    who we are and what we believe in.
                  </h5>
                  <p>
                    We're committed to doing business with integrity,
                    accountability, initiative and respect − respect for our
                    employees, business partners, clients and our communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section-s4">
        <div className="container">
          <div className="wpo-about-inner">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <h4>WELCOME To SPARK</h4>
                  <h2>We are trusted Web &amp; App development company.</h2>
                  <p>
                    At Sparks our main focus is on clients requirements and
                    needs and ensuring we provide them with only the best and
                    creative solutions for their business, at an affordable
                    price.
                  </p>
                  <div className="text-sub-wrap">
                    <div className="text-sub-item">
                      <i className="fi">
                        <img src="architect-studio/img/2.png" alt="abc" />
                      </i>
                      <h5>
                        Perfect solution of business Web &amp; App Development.
                      </h5>
                    </div>
                  </div>
                  <Link className="theme-btn-2 home" to="/services">
                    Our Services
                  </Link>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <img src="architect-studio/img/ab2.png" alt="abc" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-ab"></div>
      </section>

      <Testemonials />
      <Sponsers />
      <Contact />
    </>
  );
}
