import React from "react";

function Completion(props) {
  return (
    <section className="stats-sec padding-top padding-bottom" id="stats-sec">
      <div className="container">
        <div className="row">
          <div className="col-12 stats-heading-area text-center">
            <h4 className="heading">Delivering IT Solutions</h4>
          </div>
        </div>

        <div className="row circular-wrap text-center">
          <div className="col-12 col-lg-4 wow bounceIn">
            <div id="circle" className="circle" data-value="0.77">
              <h6 className="counter-num">{props.circont.cirfirst}</h6>
            </div>

            <h4 className="darkcolor">{props.data.optf}</h4>
          </div>

          <div className="col-12 col-lg-4 wow bounceIn">
            <div id="circletwo" className="circle" data-value="0.96">
              <h6 className="counter-num">{props.circont.cirsec}</h6>
            </div>

            <h4 className="darkcolor">{props.data.opts}</h4>
          </div>

          <div className="col-12 col-lg-4 wow bounceIn">
            <div id="circlethree" className="circle" data-value="0.75">
              <h6 className="counter-num">{props.circont.cirthr}</h6>
            </div>

            <h4 className="darkcolor">{props.data.optt}</h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Completion);
