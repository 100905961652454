import React, { useState } from "react";
import { Link } from "react-router-dom";

import Contact from "./home_Page/Contact";
import Sponsers from "./home_Page/Sponsers";
import Testemonials from "./home_Page/Testemonials";
import PageTitke from "./PageTitke";

import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function Career() {
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState(false);
  const [jobMdl, setJobMdl] = useState(false);
  const [internshipMdl, setInternshipMdl] = useState(false);
  const [disable, setdisable] = useState(false);

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
  };

  const jobValidate = Yup.object({
    fullname: Yup.string().required("Please enter the fullname"),
    emailaddress: Yup.string()
      .email()
      .required("Please enter the emailaddress"),
    phone: Yup.string().required("Please enter the phone"),
    Description: Yup.string().min(10).required("Please enter the Description"),
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        fullname: "",
        emailaddress: "",
        phone: "",
        Description: "",
      },
      validationSchema: jobValidate,
      onSubmit: (values, action) => {
        if (jobMdl) {
          applyForJob(values, action);
        } else if (internshipMdl) {
          applyForInternship(values, action);
        }
      },
    });

  function applyForJob(values, action) {
    if (file === "") {
      setFileError(true);
    } else {
      setFileError(false);
      setdisable(true);
      const name1 = values.fullname;
      const email1 = values.emailaddress;
      const Description1 = values.Description;
      const phone1 = values.phone;
      const formData = new FormData();
      formData.append("resume", file);
      formData.append("fileName", file.name);
      formData.append("name", name1);
      formData.append("email", email1);
      formData.append("description", Description1);
      formData.append("phone", phone1);

      const headers = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post("https://thesparksolutionz.com/job_api.php", formData, headers)
        .then(function (response) {
          if (response.status === 200) {
            swal("Submitted!", "Email Sent Successfully", "success");
            setJobMdl(false);
            setdisable(false);

            setFile("");
            action.resetForm();
          } else {
            swal("Error!", "Some thing went wronge!", "error");
            setdisable(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          swal("Error!", "Some thing went wronge!", "error");
          setJobMdl(false);
          setdisable(false);
        });
    }
  }

  function applyForInternship(values, action) {
    setdisable(true);
    

    const name1 = values.fullname;
    const email1 = values.emailaddress;
    const Description1 = values.Description;
    const phone1 = values.phone;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(
        "https://thesparksolutionz.com/intern_api.php",
        {
          name: name1,
          email: email1,
          description: Description1,
          phone: phone1,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          swal("Submitted!", "Email Sent Successfully", "success");
          setInternshipMdl(false);
          setdisable(false);

          action.resetForm();
        } else {
          swal("Error!", "Some thing went wronge!", "error");

          setdisable(false);
        }
      })
      .catch(function (error) {
        swal("Error!", "Some thing went wronge!", "error");
        setInternshipMdl(false);
        setdisable(false);
      });
  }

  return (
    <>
      <PageTitke tiltext="Career" />
      <section className="services-section-three padd-top">
        <div className="container">
          <div className="services">
            <div className="row clearfix">
              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-monitor"></span>
                  </div>
                  <h5>
                    laravel <br />
                    developer
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Fulltime
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> 3 Years
                  </div>
                  <div className="mt-30">
                    <Link className="btn btn-grey-small" to="/career">
                      MVC
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Skilled in DBMS
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      APIs Development
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      PHP
                    </Link>
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(true);
                        setInternshipMdl(false);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-vector"></span>
                  </div>
                  <h5>
                    UI / UX
                    <br /> Designer
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Fulltime
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> 3 Years
                  </div>
                  <div className="mt-30">
                    <Link className="btn btn-grey-small" to="/career">
                      Adobe XD
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Figma
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Premiere Pro
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Photoshop
                    </Link>
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(true);
                        setInternshipMdl(false);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-digital"></span>
                  </div>
                  <h5>
                    MARKETING <br />
                    STRATEGIST
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Fulltime
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> 1 Years
                  </div>
                  <div className="mt-30">
                    <Link className="btn btn-grey-small" to="/career">
                      Creative
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Collaborative
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Figma
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Photoshop
                    </Link>
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(true);
                        setInternshipMdl(false);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-instant-camera"></span>
                  </div>
                  <h5>
                    Digital Media <br />
                    Specialist
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Fulltime
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> 2 Years
                  </div>
                  <div className="mt-30">
                    <Link className="btn btn-grey-small" to="/career">
                      Photoshop
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Premiere Pro
                    </Link>
                    <Link className="btn btn-grey-small" to="/career">
                      Audacity
                    </Link>
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(true);
                        setInternshipMdl(false);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="services-section-bg position-relative">
        <div className="right-overlay"></div>
        <div className="container">
          <section className="services-section">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="title-block col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="sec-title">
                      <h2>
                        <span className="text-uppercase">Internship</span>{" "}
                        <br />
                        Start your career with Spark Solutionz
                        <span className="dot">.</span>
                      </h2>
                      {/* <div className="lower-text">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="services-section-three padd-top">
        <div className="container">
          <div className="services">
            <div className="row clearfix">
              <div
                className="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-vector"></span>
                    <h5>
                      graphic designer
                      <br /> part time
                    </h5>
                  </div>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Part Time
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> Fresh , 6 Months
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(false);
                        setInternshipMdl(true);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-digital"></span>
                  </div>
                  <h5>
                    seo <br />
                    specialist
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Part Time
                    </span>
                    {/*  {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> Fresh , 6 Months
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(false);
                        setInternshipMdl(true);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box career-inner-box">
                  <div className="bottom-curve"></div>
                  <div className="icon-box">
                    <span className="flaticon-instant-camera"></span>
                  </div>
                  <h5>
                    web <br />
                    designer
                  </h5>
                  <div className="fulltime-icon">
                    <span className="card-briefcase">
                      <span className="flaticon-briefcase1"></span> Part Time
                    </span>
                    {/* <span className="card-time">
                      <span className="flaticon-digital"></span>4 minutes ago
                    </span> */}
                  </div>
                  <div className="text">
                    <strong>Qualification: </strong>BS, MSC (CS/SE/IT) <br />
                    <strong>Experience: </strong> Fresh , 6 Months
                  </div>
                  <div className="apply-now-btn-box">
                    <button
                      className="btn btn-apply-now"
                      onClick={() => {
                        setJobMdl(false);
                        setInternshipMdl(true);
                      }}
                    >
                      Apply now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {jobMdl ? (
        <div className="career-modal">
          <div
            className="modal fade show d-block"
            id="ModalApplyJobForm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered  modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content apply-job-form">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setJobMdl(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="career-top-content">
                    <p className="text-brand-2">Job Application </p>
                    <h2>Start your career today</h2>
                    <p>
                      Please fill in your information and send it to the
                      employer.
                    </p>
                  </div>
                  <form
                    className="login-register text-start mt-20 pb-30"
                    method="post"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-1">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.fullname && touched.fullname ? "is-danger" : ""
                        }`}
                        id="input-1"
                        type="text"
                        required=""
                        name="fullname"
                        value={values.fullname || ""}
                        placeholder="thesparksolutionz"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.fullname && touched.fullname ? (
                        <p className="help is-danger">{errors.fullname}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-2">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.emailaddress && touched.emailaddress
                            ? "is-danger"
                            : ""
                        }`}
                        id="input-2"
                        type="email"
                        required=""
                        name="emailaddress"
                        value={values.emailaddress || ""}
                        placeholder="thesparksolutionz@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.emailaddress && touched.emailaddress ? (
                        <p className="help is-danger">{errors.emailaddress}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="number">
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.phone && touched.phone ? "is-danger" : ""
                        }`}
                        id="number"
                        type="text"
                        required=""
                        name="phone"
                        value={values.phone || ""}
                        placeholder="(+01) 234 567 89"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p className="help is-danger">{errors.phone}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="des">
                        Description <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.Description && touched.Description
                            ? "is-danger"
                            : ""
                        }`}
                        id="des"
                        type="text"
                        required=""
                        name="Description"
                        value={values.Description || ""}
                        placeholder="Your description..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.Description && touched.Description ? (
                        <p className="help is-danger">{errors.Description}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="file">
                        Upload Resume <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          fileError ? "is-danger" : ""
                        }`}
                        id="file"
                        name="resume"
                        type="file"
                        onClick={(e) => {
                          e.target.value = null;
                          setFile("");
                        }}
                        onChange={(e) => {
                          handleUpload(e);
                        }}
                      />
                      {fileError ? (
                        <p className="help is-danger">Please Select File</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="login_footer form-group d-flex justify-content-between"></div>
                    <div className="form-group">
                      <button
                        className="btn apply-job-btn w-100"
                        type="submit"
                        name="login"
                        onClick={() => {
                          if (file === "") {
                            setFileError(true);
                          } else {
                            setFileError(false);
                          }
                        }}
                        disabled={disable}
                      >
                        Apply Job
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      ) : null}
      {internshipMdl ? (
        <div className="career-modal">
          <div
            className="modal fade show d-block"
            id="ModalApplyinternshipForm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered  modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content apply-job-form">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setInternshipMdl(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="career-top-content">
                    <p className="text-brand-2">Internship Application </p>
                    <h2>Start your career today</h2>
                    <p>
                      Please fill in your information and send it to the
                      employer.
                    </p>
                  </div>
                  <form
                    className="login-register text-start mt-20 pb-30"
                    method="post"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-1">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.fullname && touched.fullname ? "is-danger" : ""
                        }`}
                        id="input-1"
                        type="text"
                        required=""
                        name="fullname"
                        value={values.fullname || ""}
                        placeholder="thesparksolutionz"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.fullname && touched.fullname ? (
                        <p className="help is-danger">{errors.fullname}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-2">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.emailaddress && touched.emailaddress
                            ? "is-danger"
                            : ""
                        }`}
                        id="input-2"
                        type="email"
                        required=""
                        name="emailaddress"
                        value={values.emailaddress || ""}
                        placeholder="thesparksolutionz@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.emailaddress && touched.emailaddress ? (
                        <p className="help is-danger">{errors.emailaddress}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="number">
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.phone && touched.phone ? "is-danger" : ""
                        }`}
                        id="number"
                        type="text"
                        required=""
                        name="phone"
                        value={values.phone || ""}
                        placeholder="(+01) 234 567 89"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p className="help is-danger">{errors.phone}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="des">
                        Description <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.Description && touched.Description
                            ? "is-danger"
                            : ""
                        }`}
                        id="des"
                        type="text"
                        required=""
                        name="Description"
                        value={values.Description || ""}
                        placeholder="Your description..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.Description && touched.Description ? (
                        <p className="help is-danger">{errors.Description}</p>
                      ) : null}
                    </div>

                    <div className="login_footer form-group d-flex justify-content-between"></div>
                    <div className="form-group">
                      <button
                        className="btn apply-job-btn w-100"
                        type="submit"
                        name="login"
                        disabled={disable}
                      >
                        Apply Job
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      ) : null}

      <div className={`preloader ${disable ? "fadeIn" : "fadeOut"}`}>
        <div className="vertical-centered-box">
          <div className="content">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
            <img src="architect-studio/img/favicon.png" alt="logo" />
          </div>
        </div>
      </div>

      <Testemonials />
      <Sponsers />
      <Contact />
    </>
  );
}
