import React from "react";

function Testemonials() {
  return (
    <div
      className="testimonial-sec padding-top position-relative"
      id="testimonial-sec"
    >
      <div className="right-overlay"></div>

      <div className="container">
        <div className="testimonial-area padding-top padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center text-center text-lg-left">
                <div className="testimonial-details wow fadeInLeft">
                  <h4 className="heading">
                    SATISFIED <span>CUSTOMERS</span>
                  </h4>
                </div>
              </div>

              <div className="col-12 col-lg-6 offset-lg-1">
                <div className="testimonial-carousel wow fadeInRight">
                  <div className="testimonial-box owl-carousel owl-theme">
                    <div className="item text-center">
                      <div className="icon-holder">
                        <i className="fa fa-quote-right"></i>
                      </div>

                      <p className="text">
                        Nadeem has excellent design sense and is very willing to
                        work with you until he comes back with a design that you
                        truly love. Some of the attention to detail could be
                        improved as I had to repeat some information during
                        discussion and a few of the visual elements weren't
                        consistent in initial drafts, but the end result is
                        polished and I'm quite happy.
                      </p>

                      <div className="img-holder">
                        <img src="architect-studio/img/test2.jpg" alt="test2" />
                      </div>

                      <h4 className="user-name">David Shapiro</h4>
                    </div>

                    <div className="item text-center">
                      <div className="icon-holder">
                        <i className="fa fa-quote-right"></i>
                      </div>

                      <p className="text">
                        Professional and On time. I highly recommend Nadeem.
                      </p>

                      <div className="img-holder">
                        <img src="architect-studio/img/test2.jpg" alt="test2" />
                      </div>

                      <h4 className="user-name">Darbslayos</h4>
                    </div>

                    <div className="item text-center">
                      <div className="icon-holder">
                        <i className="fa fa-quote-right"></i>
                      </div>

                      <p className="text">
                        Spark and their team did an excellent job on this
                        Project. They worked late and on weekends to get the
                        work finished and went the extra mile to help us get
                        what we needed. I would highly recommend them and will
                        use them again in the future.
                      </p>

                      <div className="img-holder">
                        <img src="architect-studio/img/test1.jpg" alt="test2" />
                      </div>

                      <h4 className="user-name">Rusi</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(Testemonials);
