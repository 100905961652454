import Contact from './home_Page/Contact'
import Sponsers from './home_Page/Sponsers'
import Testemonials from './home_Page/Testemonials'
import PageTitke from './PageTitke'

export default function TestemonialsPage() {
  return (
  <>  
    <PageTitke tiltext="Testimonial"/>

    <div className="service-area testimonial-area">
        <div className="container">
            <div className="row custom-gutters-16">
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                  
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>JOHNSON MATTA</h6>
                                    <p>10 is not nearly a high enough rating. I mean it! They created a template precisely how I wanted it after all my suggestions and input regardless of the amount of work and edits. They really wanted to make sure I had exactly what I wanted above everything else. I feel so blessed to have found Sparks, and will be doing many more projects with them. </p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                      
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>WILLIAM</h6>
                                    <p>We are completely thrilled with the work we have received from Sparks team. They are professional, timely and go the extra mile to get our work done. We would recommend them for future work and are happy to be a reference for anyone who is interested in learning more about their work.</p>
                                    {/* <div className="read-more">
                                        <a href="#"><img src="architect-studio/img/arrow.png" alt="arrow"></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
              
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>DANIEL</h6>
                                    <p>These guys are my No. 1 choice, I highly recommend them, they designed and developed my website exactly how i wanted. I must say they are growing very fast and their skills are extraordinary as compare to the time Sparks was found. Great Job Guys !!!!!</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                          
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>TAUNA ROBLEY</h6>
                                    <p>Sparks and their team did an excellent job on this Project. They worked late and on weekends to get the work finished and went the extra mile to help us get what we needed. I would highly recommend them and will use them again in the future.</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                      
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>ADAM</h6>
                                    <p>Great Job Sparks, you got 10 rating for doing such a nice job. I am available for future reference.</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                      
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>LUNA COUSIN</h6>
                                    <p>I love you Guys, the way you did my project I'm thrilled, I need so much help on my main site I would like Sparks to be my permanent I.T. Consultant.</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                      
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>JARED</h6>
                                    <p>Sparks were the best team I could ever ask for. They were professional, fast, and creative. They stuck to my extremely short schedule and delivered above and beyond expectation.</p>
                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="single-service">
                        <div className="row">
                          
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>TRI HARTONO</h6>
                                    <p>How about another project guys? I'm totally surprised with your team work. Sparks did a great job. Next project is waiting for you.</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-xl-12 col-md-12 col-sm-12">
                    <div className="single-service">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12">
                                <div className="testimonial-area-content">
                                    <h6>DOUGLAS</h6>
                                    <p>I contacted many people online but no one was able to understand my requirements, I got email from Sparks. I was not sure about him and his team as they didn't had any previous work history but somehow Adeel convinced me for making the design and when I saw the design I jumped from my chair. I gave them my development project as well and their programming skills in PHP were awesome, I must say I made a right choice, I look forward to work with them soon. When it comes to requirement understanding Sparks directly hits the target. I highly recommend them for web development and design.</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>

    <Testemonials />
    <Sponsers />
    <Contact />
  </>
  )
}
