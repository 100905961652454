import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
  return (
<section className="blog-sec" id="blog-sec">

<div className="left-overlay"></div>

<div className="container position-relative">

    <div className="blog-inner-overlay"></div>

    <div className="row blog-area">

        <div className="col-12 col-lg-5 d-flex align-items-center text-center text-lg-left">

            <div className="blog-detail wow fadeInLeft">

                <h4 className="heading text-white">WHAT MAKES <span>US DIFFERENT</span></h4>

                <p className="text text-white">

                    With our approach to Web Design, our methods continue to be proven in a continuous challenging marketplace with delivering what we promise.

                </p>

                <Link className="btn anim-btn rounded-pill home" to="/about">LEARN MORE

                <span></span><span></span><span></span><span></span>

                </Link>

            </div>

        </div>

        <div className="col-12 col-lg-6 offset-lg-1">

            <div className="blog-img wow fadeInRight">

                <img src="architect-studio/img/blog1.jpg" alt="blog-img" />

            </div>

        </div>

    </div>

</div>

</section>
  )
}
