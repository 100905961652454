import { useContext } from "react";
import { Link } from "react-router-dom";
import CompanyDetails from "../context/companyDetailsContext";



export default function PageTitke(props) {
    const companyDetails = useContext(CompanyDetails);

  return (
<section className="slider-area breadcrumb-area wow fadeInLeft" data-wow-delay=".8s">
    <div className="bg-overlay"></div>
    <div className="container position-relative">
        <div className="inner-bg-overlay"></div>
        <div className="breadcrumb-inner">
            <h1 className="page-title">{props.tiltext}</h1>
            <ul className="page-list">
                <li><Link className="home" to="/">Home</Link></li>
                <li>{props.tiltext}</li>
            </ul>
        </div>
    </div>
    <div className="slider-icons">
        <ul className="slider-social banner-social">
            <li className="animated-wrap"><a className="animated-element" target="blank" href={companyDetails.comdetail.linkdtl.facebook}><i className="fa fa-facebook-f"></i> </a></li>
            <li className="animated-wrap"><a className="animated-element" target="blank" href={companyDetails.comdetail.linkdtl.linkedin}><i className="fa fa-linkedin-in"></i> </a></li>
            <li className="animated-wrap"><a className="animated-element" target="blank" href={companyDetails.comdetail.linkdtl.instagram}><i className="fa fa-instagram"></i> </a></li>
      </ul>
    </div>
</section>
  )
}
