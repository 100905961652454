import React from "react";
import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <section
      className="portfolio-sec company-portfolio-section padding-top"
      id="company-portfolio-section"
    >
      <div className="container">
        <div className="section-heading">
          <div className="row">
            <div
              className="col-lg-6 wow fadeInUp text-center text-lg-left"
              data-wow-delay="300ms"
            >
              <h4 className="heading">
                OUR <span>AMAZING PORTFOLIO</span>
              </h4>
            </div>

            <div className="col-md-12 pt-5">
              <div
                id="js-filters-mosaic"
                className="cbp-l-filters-button wow fadeInUp text-center text-lg-left"
                data-wow-delay="350ms"
              >
                <div
                  data-filter="*"
                  className="cbp-filter-item-active cbp-filter-item"
                >
                  {" "}
                  ALL
                </div>

                <div data-filter=".web" className="cbp-filter-item">
                  DESIGN
                </div>

                <div data-filter=".logo" className="cbp-filter-item">
                  HTML & CSS
                </div>

                <div data-filter=".mobile" className="cbp-filter-item">
                  SEO
                </div>

                <div data-filter=".development" className="cbp-filter-item">
                  DEVELOPMENT
                </div>
              </div>

              <div
                id="js-grid-mosaic"
                className="cbp cbp-l-grid-mosaic wow fadeInUp"
              >
                <div className="cbp-item web logo">
                  <a
                    href="architect-studio/img/p1.jpg"
                    className="cbp-caption cbp-lightbox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img src="architect-studio/img/p1.jpg" alt="img" />
                    </div>

                    <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                      <div className="portfolio-inner-content">
                        <span></span>

                        <span></span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="cbp-item mobile web">
                  <a
                    href="architect-studio/img/p2.jpg"
                    className="cbp-caption cbp-lightbox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img src="architect-studio/img/p2.jpg" alt="img" />
                    </div>

                    <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                      <div className="portfolio-inner-content">
                        <span></span>

                        <span></span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="cbp-item print logo development web mobile">
                  <a
                    href="architect-studio/img/p3.jpg"
                    className="cbp-caption cbp-lightbox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img src="architect-studio/img/p3.jpg" alt="img" />
                    </div>

                    <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                      <div className="portfolio-inner-content">
                        <span></span>

                        <span></span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="cbp-item logo development">
                  <a
                    href="architect-studio/img/p4.jpg"
                    className="cbp-caption cbp-lightbox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img src="architect-studio/img/p4.jpg" alt="img" />
                    </div>

                    <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                      <div className="portfolio-inner-content">
                        <span></span>

                        <span></span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div
                id="js-loadMore-lightbox-gallery"
                className="cbp-l-loadMore-button offset-xl-6 pl-xl-3 text-data wow fadeInUp"
                data-wow-delay="650ms"
              >
                <div className="row portfolio-foot-detail text-data-inner">
                  <div className="col-12 col-lg-8  text-center text-lg-left pl-4">
                    <span className="p-text">We've Completed More Then</span>

                    <h4 className="p-num text-yellow">530</h4>

                    <span className="p-text">
                      Projects for Our amazing Clients
                    </span>
                  </div>

                  <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <Link
                      to="/portfolio"
                      className="btn black-btn rounded-pill home"
                    >
                      VIEW ALL<span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Portfolio);
