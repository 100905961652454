import { useContext } from "react";
import CompanyDetails from "../context/companyDetailsContext";
import Contact from "./home_Page/Contact";
import Sponsers from "./home_Page/Sponsers";
import Testemonials from "./home_Page/Testemonials";

export default function SubService(props) {
  const companyDetails = useContext(CompanyDetails);

  return (
    <>
      <section
        className="slider-area breadcrumb-area wow fadeInLeft"
        data-wow-delay=".8s"
      >
        <div className="bg-overlay"></div>
        <div className="container position-relative">
          <div className="inner-bg-overlay"></div>
          <div className="breadcrumb-inner">
            <h1 className="page-title">{props.name}</h1>
          </div>
        </div>
        <div className="slider-icons">
          <ul className="slider-social banner-social">
            <li className="animated-wrap">
              <a
                className="animated-element"
                target="blank"
                href={companyDetails.comdetail.linkdtl.facebook}
              >
                <i className="fa fa-facebook-f"></i>{" "}
              </a>
            </li>
            <li className="animated-wrap">
              <a
                className="animated-element"
                target="blank"
                href={companyDetails.comdetail.linkdtl.linkedin}
              >
                <i className="fa fa-linkedin-in"></i>{" "}
              </a>
            </li>
            <li className="animated-wrap">
              <a
                className="animated-element"
                target="blank"
                href={companyDetails.comdetail.linkdtl.instagram}
              >
                <i className="fa fa-instagram"></i>{" "}
              </a>
            </li>
          </ul>
        </div>
      </section>

      <div
        className="service-area pd-top-112 wow fadeInUp"
        data-wow-delay=".8s"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <img src={props.pict} alt="servi-pic" />
            </div>
            <div className="col-xl-12">
              <div className="services-detail-box">
                <h2 className="title">{props.name}</h2>
                <p>{props.para}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testemonials />

      <Sponsers />

      <Contact />
    </>
  );
}
