import React from "react";
import CompanyContext from "./companyDetailsContext";
const companyDetails = (props) => {
  var date = new Date().getFullYear();

  const comdetail = {
    textdtl: {
      address: "Office # 73, Street # 3 Abdullah Colony, Sargodha",
      address2: "1st floor Market 4, G-10/4, Islamabad",
      address3: "501-R1, Johar Town, Lahore",
      email: "info@thesparksolutionz.com",
      phone: "+92-315-7351313",
      copytext: "© Copyrights " + date + ". All Rights Reserved.",
    },
    linkdtl: {
      address: "https://goo.gl/maps/HKf9EuQ86wSzgvbg9",
      email: "mailto:info@thesparksolutionz.com",
      phone: "tel:+923157351313",
      facebook: "https://www.facebook.com/SparkSolutionz/",
      linkedin: "https://www.linkedin.com/company/spark-solutionz/",
      instagram: "https://www.instagram.com/sparksolutionz/",
    },
  };

  return (
    <CompanyContext.Provider value={{ comdetail }}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export default companyDetails;
