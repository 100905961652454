import React, { useContext } from "react";
import CompanyDetails from "../context/companyDetailsContext";

import { Link } from "react-router-dom";
function Header() {
  const companyDetails = useContext(CompanyDetails);

  return (
    <header id="home" className="cursor-light">
      <div className="inner-header">
        <div className="color-lines position-relative">
          <div className="container">
            <div className="row">
              <div className="col-12 bg-color"></div>
            </div>
          </div>

          <div className="color-line-overlay"></div>
        </div>

        <div className="main-navigation">
          <div className="container">
            <div className="row">
              <div className="col-6 col-lg-3">
                <Link className="navbar-brand simple-nav-logo link home" to="/">
                  <img src="/architect-studio/img/logo-black.png" alt="logo" />
                </Link>

                <Link className="navbar-brand fixed-nav-logo link home" to="/">
                  <img src="/architect-studio/img/logo-black.png" alt="logo" />
                </Link>
              </div>

              <div
                className="col-8 col-lg-9 simple-navbar d-flex align-items-center justify-content-end"
                id="simple-navbar"
              >
                <nav className="navbar navbar-expand-lg">
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item active">
                        <Link className="nav-link home link " to="/">
                          <span>0</span>
                          <span data-count="1" className="num-nav">
                            1
                          </span>
                          <span>.</span>HOME
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link home link" to="/about">
                          <span>0</span>
                          <span data-count="2" className="num-nav">
                            2
                          </span>
                          <span>.</span>ABOUT
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link home link"
                          to=""
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span>0</span>
                          <span data-count="3" className="num-nav">
                            3
                          </span>
                          <span>.</span>SERVICES
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/services" className="home">
                              All Services
                            </Link>
                          </li>
                          <li>
                            <Link to="/custom-logo" className="home">
                              Custom logo design
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-development" className="home">
                              Website development
                            </Link>
                          </li>
                          <li>
                            <Link to="/app-development" className="home">
                              App development
                            </Link>
                          </li>
                          <li>
                            <Link to="/uiux-design" className="home">
                              UI/UX designing
                            </Link>
                          </li>
                          <li>
                            <Link to="/api-implementation" className="home">
                              API implementation
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-marketing" className="home">
                              Digital Marketing
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link home link" to="/portfolio">
                          <span>0</span>
                          <span data-count="4" className="num-nav">
                            4
                          </span>
                          <span>.</span>PORTFOLIO
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link home link" to="/testimonials">
                          <span>0</span>
                          <span data-count="5" className="num-nav">
                            5
                          </span>
                          <span>.</span>TESTIMONIALS
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link home link"
                          to="/contact"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span>0</span>
                          <span data-count="6" className="num-nav">
                            6
                          </span>
                          <span>.</span>CONTACT
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/contact" className="home">
                              Contact Us
                            </Link>
                          </li>
                          <li>
                            <Link to="/career" className="home">
                              Career
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <button className="sidemenu_btn link" id="sidemenu_toggle">
          <span></span>

          <span></span>

          <span></span>
        </button>
      </div>

      <div className="side-menu side-menu-opacity">
        <div className="bg-overlay"></div>

        <div className="inner-wrapper">
          <span className="btn-close" id="btn_sideNavClose">
            <i></i>
            <i></i>
          </span>

          <div className="container">
            <div className="row w-100 side-menu-inner-content">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <Link to="/" className="navbar-brand home">
                  <img src="/architect-studio/img/logo.png" alt="logo" />
                </Link>
              </div>

              <div className="col-12 col-lg-8">
                <nav className="side-nav w-100">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link home" to="/">
                        HOME
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link home" to="/about">
                        ABOUT
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link home" to="/services">
                        SERVICES
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link home" to="/portfolio">
                        PORTFOLIO
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link home" to="/testimonials">
                        TESTIMONIALS
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link home" to="/contact">
                        CONTACT
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link home" to="/career">
                        CAREER
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="col-12 col-lg-4 d-flex align-items-center">
                <div className="side-footer text-white w-100">
                  <div className="menu-company-details">
                    <span>
                      <a href={companyDetails.comdetail.linkdtl.phone}>
                        {companyDetails.comdetail.textdtl.phone}
                      </a>
                    </span>

                    <span>
                      <a href={companyDetails.comdetail.linkdtl.email}>
                        {companyDetails.comdetail.textdtl.email}
                      </a>
                    </span>
                  </div>

                  <ul className="social-icons-simple">
                    <li>
                      <a
                        className="facebook-text-hvr"
                        target="blank"
                        href={companyDetails.comdetail.linkdtl.facebook}
                      >
                        <i className="fa fa-facebook-f"></i>{" "}
                      </a>
                    </li>

                    <li>
                      <a
                        className="linkedin-text-hvr"
                        target="blank"
                        href={companyDetails.comdetail.linkdtl.linkedin}
                      >
                        <i className="fa fa-linkedin-in"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        className="instagram-text-hvr"
                        target="blank"
                        href={companyDetails.comdetail.linkdtl.instagram}
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>

                  <p className="text-white">
                    {companyDetails.comdetail.textdtl.copytext}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default React.memo(Header);
