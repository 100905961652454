import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CompanyDetails from "../../context/companyDetailsContext";

function HomeBanner() {
  const companyDetails = useContext(CompanyDetails);
  return (
    <section className="slider-area cursor-light">
      <div className="bg-overlay"></div>

      <div className="container position-relative">
        <div className="inner-bg-overlay"></div>

        <div className="row">
          <div
            className="slider-detail col-12 col-lg-6 text-center text-lg-left wow fadeInLeft"
            data-wow-delay=".8s"
          >
            <div className="slider-slide">
              <div className="slider-inner-content">
                <h4 className="slide-heading">
                  WELCOME TO <span>SPARK SOLUTIONZ</span>
                </h4>

                <p className="slide-text">
                  Spark Solutionz is a Pakistan based web application
                  development company providing a wide range of web development
                  solutions locally as well as all over the world. We pride in
                  providing innovative and unique web application development
                  solutions to all your business needs.
                </p>

                <Link to="/about" className="btn anim-btn rounded-pill scroll">
                  LEARN MORE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="slider-slide">
              <div className="slider-inner-content">
                <h4 className="slide-heading">
                  WELCOME TO <span>SPARK SOLUTIONZ</span>
                </h4>

                <p className="slide-text">
                  Spark Solutionz is a Pakistan based web application
                  development company providing a wide range of web development
                  solutions locally as well as all over the world. We pride in
                  providing innovative and unique web application development
                  solutions to all your business needs.
                </p>

                <Link to="/about" className="btn anim-btn rounded-pill scroll">
                  LEARN MORE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="slider-slide">
              <div className="slider-inner-content">
                <h4 className="slide-heading">
                  WELCOME TO <span>SPARK SOLUTIONZ</span>
                </h4>

                <p className="slide-text">
                  Spark Solutionz is a Pakistan based web application
                  development company providing a wide range of web development
                  solutions locally as well as all over the world. We pride in
                  providing innovative and unique web application development
                  solutions to all your business needs.
                </p>

                <Link to="/about" className="btn anim-btn rounded-pill scroll">
                  LEARN MORE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="slider-img col-12 col-lg-6 wow fadeInRight"
            data-wow-delay=".8s"
            data-depth="0.1"
          >
            <div className="img-slide">
              <img src="architect-studio/img/slide1.jpg" alt="slider" />
            </div>

            <div className="img-slide">
              <img src="architect-studio/img/slide2.jpg" alt="slider" />
            </div>

            <div className="img-slide">
              <img src="architect-studio/img/slide3.jpg" alt="slider" />
            </div>
          </div>
        </div>

        <div className="slider-arrows">
          <button className="slider-arr slider-arr-up link" id="slider-arr-up">
            <i className="fa fa-angle-up"></i>
          </button>

          <button
            className="slider-arr slider-arr-down link"
            id="slider-arr-down"
          >
            <i className="fa fa-angle-down"></i>
          </button>
        </div>
      </div>

      <div className="slider-icons">
        <ul className="slider-social banner-social">
          <li className="animated-wrap">
            <a
              className="animated-element"
              target="blank"
              href={companyDetails.comdetail.linkdtl.facebook}
            >
              <i className="fa fa-facebook-f"></i>{" "}
            </a>
          </li>

          <li className="animated-wrap">
            <a
              className="animated-element"
              target="blank"
              href={companyDetails.comdetail.linkdtl.linkedin}
            >
              <i className="fa fa-linkedin-in"></i>{" "}
            </a>
          </li>

          <li className="animated-wrap">
            <a
              className="animated-element"
              target="blank"
              href={companyDetails.comdetail.linkdtl.instagram}
            >
              <i className="fa fa-instagram"></i>{" "}
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
export default React.memo(HomeBanner);
