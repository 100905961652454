export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (values.email.trim().length === 0) {
    errors.email = "email must contain characters";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  // if (!values.password) {
  //   errors.password = 'Password is required';
  // } else if (values.password.length < 8) {
  //   errors.password = 'Password must be 8 or more characters';
  // }
  if (!values.name) {
    errors.name = "Name is required";
  } else if (values.name.trim().length === 0) {
    errors.name = "Name must contain characters";
  } else if (values.name.length < 2) {
    errors.name = "Name must be 2 or more characters";
  }

  if (!values.message) {
    errors.message = "Message is required";
  } else if (values.message.trim().length === 0) {
    errors.message = "Message must contain characters";
  } else if (values.message.length < 10) {
    errors.message = "Message must be 10 or more characters";
  }

  if (!values.service) {
    errors.service = "Please select a service";
  }

  if (!values.phone) {
    errors.phone = "Phone is required";
  } else if (values.phone.trim().length === 0) {
    errors.phone = "Phone must contain numbers";
  } else if (isNaN(values.phone)) {
    errors.phone = "Phone must contain numbers";
  } else if (values.phone.length < 8) {
    errors.phone = "Phone must be 8 or more characters";
  }

  return errors;
}
