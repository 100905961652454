import { useContext } from "react";
import CompanyDetails from "../../context/companyDetailsContext";

export default function Contact() {
  const companyDetails = useContext(CompanyDetails);
  return (
    <section className="contact-sec padding-bottom" id="contact-sec">
      <div className="container">
        <div className="row">
          {/* <div className="col-12 col-lg-7">
            <div className="wpo-contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6762.1774430348605!2d72.682752!3d32.066849!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe6434929405fb81a!2sSparkSolutionz!5e0!3m2!1sen!2sus!4v1658484984378!5m2!1sen!2sus"
                width="100%"
                height="350"
                allowFullScreen={true}
                loading="lazy"
                title="map location"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div> */}
          <div className="col-12 stats-heading-area text-center">
            <h4 className="heading mb-0">Our Branches</h4>
          </div>
          <div className="col-12 col-lg-4 text-center text-lg-left position-relative">
            <div className="contact-details wow fadeInRight">
              <ul>
                <li>
                  <i className="las la-map-marker addr"></i>
                  <a>{companyDetails.comdetail.textdtl.address2}</a>
                </li>

                <li>
                  <i className="las la-phone-volume phone"></i>

                  <span>
                    <a href={companyDetails.comdetail.linkdtl.phone}>
                      {companyDetails.comdetail.textdtl.phone}
                    </a>
                  </span>

                  <span></span>
                </li>

                <li>
                  <i className="las la-paper-plane email"></i>
                  <a href={companyDetails.comdetail.linkdtl.email}>
                    {companyDetails.comdetail.textdtl.email}
                  </a>
                </li>
              </ul>
            </div>

            <img
              src="architect-studio/img/contact-background.png"
              className="contact-background"
              alt="contact"
            />
          </div>
          <div className="col-12 col-lg-4 text-center text-lg-left position-relative">
            <div className="contact-details wow fadeInRight">
              <ul>
                <li>
                  <i className="las la-map-marker addr"></i>
                  <a>{companyDetails.comdetail.textdtl.address3}</a>
                </li>

                <li>
                  <i className="las la-phone-volume phone"></i>

                  <span>
                    <a href={companyDetails.comdetail.linkdtl.phone}>
                      {companyDetails.comdetail.textdtl.phone}
                    </a>
                  </span>

                  <span></span>
                </li>

                <li>
                  <i className="las la-paper-plane email"></i>
                  <a href={companyDetails.comdetail.linkdtl.email}>
                    {companyDetails.comdetail.textdtl.email}
                  </a>
                </li>
              </ul>
            </div>

            <img
              src="architect-studio/img/contact-background.png"
              className="contact-background"
              alt="contact"
            />
          </div>
          <div className="col-12 col-lg-4 text-center text-lg-left position-relative">
            <div className="contact-details wow fadeInRight">
              <ul>
                <li>
                  <i className="las la-map-marker addr"></i>
                  <a
                  // href={companyDetails.comdetail.linkdtl.address}
                  // target="blank"
                  >
                    {companyDetails.comdetail.textdtl.address}
                  </a>
                </li>

                <li>
                  <i className="las la-phone-volume phone"></i>

                  <span>
                    <a href={companyDetails.comdetail.linkdtl.phone}>
                      {companyDetails.comdetail.textdtl.phone}
                    </a>
                  </span>

                  <span></span>
                </li>

                <li>
                  <i className="las la-paper-plane email"></i>
                  <a href={companyDetails.comdetail.linkdtl.email}>
                    {companyDetails.comdetail.textdtl.email}
                  </a>
                </li>
              </ul>
            </div>

            <img
              src="architect-studio/img/contact-background.png"
              className="contact-background"
              alt="contact"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
