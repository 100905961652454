import React from "react";
import { Link } from "react-router-dom";
import Contact from "./home_Page/Contact";
import Sponsers from "./home_Page/Sponsers";
import Testemonials from "./home_Page/Testemonials";
import PageTitke from "./PageTitke";

export default function ServicesPage() {
  return (
    <>
      <PageTitke tiltext="Services" />

      <section className="services-section-three padd-top">
        <div className="container">
          <div className="services">
            <div className="row clearfix">
              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <Link to="/custom-logo" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-vector"></span>
                    </div>

                    <h5>
                      Custom Logo <br />
                      Design
                    </h5>

                    <div className="text">
                      In this age of technology as online business...
                    </div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <Link to="/content-management" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-digital"></span>
                    </div>

                    <h5>
                      Content Management <br />
                      System
                    </h5>

                    <div className="text">
                      Spark believes in developing content management...
                    </div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <Link to="/print-media-design" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-instant-camera"></span>
                    </div>

                    <h5>
                      Print Media <br />
                      Design
                    </h5>

                    <div className="text">
                      Ranging from your company's promotional material...
                    </div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="900ms"
                data-wow-duration="1500ms"
              >
                <Link to="/web-design-redesign" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-monitor"></span>
                    </div>

                    <h5>
                      Web Design <br />
                      Redesign
                    </h5>

                    <div className="text">
                      The logo of your company is the most...
                    </div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="services-section-bg position-relative">
        <div className="right-overlay"></div>

        <div className="container">
          <section className="services-section">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="title-block col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="sec-title">
                      <h2>
                        We Shape the Perfect <br />
                        Solutions<span className="dot">.</span>
                      </h2>

                      <div className="lower-text">
                        We are committed to providing our customers with
                        exceptional service while offering our employees the
                        best training.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/web-development" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-responsive"></span>
                      </div>

                      <h6>
                        a Website <br />
                        Development
                      </h6>
                    </div>
                  </Link>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/graphic-designing" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-computer"></span>
                      </div>

                      <h6>
                        graphic <br />
                        designing
                      </h6>
                    </div>
                  </Link>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/digital-marketing" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-digital-marketing"></span>
                      </div>

                      <h6>
                        digital <br />
                        marketing
                      </h6>
                    </div>
                  </Link>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/seo-content-writing" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-development"></span>
                      </div>

                      <h6>
                        seo &amp; content <br />
                        writing
                      </h6>
                    </div>
                  </Link>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/app-development" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-app-development"></span>
                      </div>

                      <h6>
                        App <br />
                        Development
                      </h6>
                    </div>
                  </Link>
                </div>

                <div className="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <Link to="/uiux-design" className="home">
                    <div className="inner-box">
                      <div className="bottom-curve"></div>

                      <div className="icon-box">
                        <span className="flaticon-ui"></span>
                      </div>

                      <h6>
                        Ui/UX <br />
                        designing
                      </h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="services-section-three padd-top">
        <div className="container">
          <div className="services">
            <div className="row clearfix">
              <div
                className="service-block-two col-xl-4 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <Link to="/mobile-application" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-mobile-analytics"></span>
                    </div>

                    <h5>
                      Mobile <br />
                      Application
                    </h5>

                    <div className="text">
                      In today’s modern world so many...
                    </div>

                    <div className="link-box">
                      <span className="flaticon-mobile-analytics"></span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="service-block-two col-xl-4 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <Link to="/api-implementation" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-data"></span>
                    </div>

                    <h5>
                      API <br />
                      Implementation
                    </h5>

                    <div className="text">What is API Implementation...</div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="service-block-two col-xl-4 col-lg-4 col-md-12 col-sm-12 wow fadeInUp"
                data-wow-delay="900ms"
                data-wow-duration="1500ms"
              >
                <Link to="/website-maintenance" className="home">
                  <div className="inner-box">
                    <div className="bottom-curve"></div>

                    <div className="icon-box">
                      <span className="flaticon-planning"></span>
                    </div>

                    <h5>
                      Website <br />
                      Maintenance
                    </h5>

                    <div className="text">We believe businesses should..</div>

                    <div className="link-box">
                      <span className="fa fa-angle-right"></span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="testimonial-services">
        <Testemonials />
      </div>

      <Sponsers />
      <Contact />
    </>
  );
}
