import React from "react";

function Sponsers() {
  return (
    <div className="sponser-sec padding-top" id="sponser-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="sponser-tags owl-carousel owl-theme">
              <div className="item">
                <img src="architect-studio/img/sama.png" alt="logo" />
              </div>

              <div className="item">
                <img src="architect-studio/img/dct.png" alt="logo" />
              </div>

              <div className="item">
                <img src="architect-studio/img/glass.png" alt="logo" />
              </div>

              <div className="item">
                <img src="architect-studio/img/dream.png" alt="logo" />
              </div>

              <div className="item">
                <img src="architect-studio/img/lapto.png" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(Sponsers);
