import Contact from "./home_Page/Contact";
import Sponsers from "./home_Page/Sponsers";
import Testemonials from "./home_Page/Testemonials";
import PageTitke from "./PageTitke";

export default function PortfolioPage() {
  return (
    <>
      <PageTitke tiltext="Portfolio" />

      <section
        className="portfolio-sec company-portfolio-section padding-top"
        id="company-portfolio-section"
      >
        <div className="container">
          <div className="section-heading">
            <div className="row">
              {/* <div
                className="col-lg-6 wow fadeInUp text-center text-lg-left"
                data-wow-delay="300ms"
              >
                <h4 className="heading">
                  OUR <span>AMAZING PORTFOLIO</span>
                </h4>
              </div> */}

              <div className="col-md-12 pt-0">
                <div
                  id="js-filters-mosaic-page"
                  className="cbp-l-filters-button wow fadeInUp text-center text-lg-left port-page"
                  data-wow-delay="350ms"
                >
                  <div
                    data-filter="*"
                    className="cbp-filter-item-active cbp-filter-item"
                  >
                    {" "}
                    ALL
                  </div>

                  <div data-filter=".web" className="cbp-filter-item">
                    DESIGN
                  </div>

                  <div data-filter=".logo" className="cbp-filter-item">
                    HTML & CSS
                  </div>

                  <div data-filter=".mobile" className="cbp-filter-item">
                    SEO
                  </div>

                  <div data-filter=".development" className="cbp-filter-item">
                    DEVELOPMENT
                  </div>
                </div>

                <div
                  id="js-grid-mosaic-page"
                  className="cbp cbp-l-grid-mosaic port-page wow fadeInUp"
                >
                  <div className="cbp-item web logo">
                    <a
                      href="architect-studio/img/p1.jpg"
                      className="cbp-caption cbp-lightbox"
                      data-title=""
                    >
                      <div className="cbp-caption-defaultWrap">
                        <img src="architect-studio/img/p1.jpg" alt="img" />
                      </div>

                      <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                        <div className="portfolio-inner-content">
                          <span></span>

                          <span></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="cbp-item mobile web">
                    <a
                      href="architect-studio/img/p2.jpg"
                      className="cbp-caption cbp-lightbox"
                      data-title=""
                    >
                      <div className="cbp-caption-defaultWrap">
                        <img src="architect-studio/img/p2.jpg" alt="img" />
                      </div>

                      <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                        <div className="portfolio-inner-content">
                          <span></span>

                          <span></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="cbp-item print logo development web mobile">
                    <a
                      href="architect-studio/img/p3.jpg"
                      className="cbp-caption cbp-lightbox"
                      data-title=""
                    >
                      <div className="cbp-caption-defaultWrap">
                        <img src="architect-studio/img/p3.jpg" alt="img" />
                      </div>

                      <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                        <div className="portfolio-inner-content">
                          <span></span>

                          <span></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="cbp-item logo development">
                    <a
                      href="architect-studio/img/p4.jpg"
                      className="cbp-caption cbp-lightbox"
                      data-title=""
                    >
                      <div className="cbp-caption-defaultWrap">
                        <img src="architect-studio/img/p4.jpg" alt="img" />
                      </div>

                      <div className="cbp-caption-activeWrap portfolio-hover-effect d-flex align-items-end">
                        <div className="portfolio-inner-content">
                          <span></span>

                          <span></span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testemonials />
      <Sponsers />
      <Contact />
    </>
  );
}
