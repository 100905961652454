import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Services() {
  let navigate = useNavigate();

  return (
    <section className="about-sec" id="about-sec">
      <div className="about-overlay"></div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 services-area padding-top padding-bottom">
            <div className="purple-overlay"></div>

            <div className="row no-gutters wow fadeInLeft">
              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/web-development");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img src="architect-studio/img/5.png" alt="service-icon" />
                  </div>

                  <h4 className="card-heading">Web Development</h4>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/app-development");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img
                      src="architect-studio/img/app-dev.png"
                      alt="service-icon"
                    />
                  </div>

                  <h4 className="card-heading">App Development</h4>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/graphic-designing");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img src="architect-studio/img/6.png" alt="service-icon" />
                  </div>

                  <h4 className="card-heading">Graphic Designing</h4>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/seo-content-writing");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img src="architect-studio/img/7.png" alt="service-icon" />
                  </div>

                  <h4 className="card-heading">SEO Optimization</h4>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/uiux-design");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img src="architect-studio/img/9.png" alt="service-icon" />
                  </div>

                  <h4 className="card-heading">UI/UX Designing</h4>
                </div>
              </div>

              <div
                className="col-12 col-lg-6 services text-center home"
                onClick={() => {
                  navigate("/website-maintenance");
                }}
              >
                <div className="service-card">
                  <div className="icon-holder">
                    <img
                      src="architect-studio/img/support.png"
                      alt="service-icon"
                    />
                  </div>

                  <h4 className="card-heading">Website Maintenance</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 about-area padding-top padding-bottom text-center text-lg-left">
            <div className="about-content wow fadeInRight">
              <div className="about-inner-content">
                <h4 className="heading">
                  OUR <span className="text-yellow">SERVICES</span>
                </h4>

                <p className="text">
                  We are Pakistan based web application development company that
                  you can rely on to offer high quality programming and
                  exceptional services. Our services are limitless when it comes
                  to web development and design solutions. We strive in
                  providing our clients with only the best solutions and what
                  matters the most to their business.
                </p>

                <p className="text">
                  With our approach to Web Design, our methods continue to be
                  proven in a continuous challenging marketplace with delivering
                  what we promise.
                </p>

                <Link
                  to="/about"
                  className="btn white-trans-btn rounded-pill home"
                >
                  LEARN MORE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Services);
