import React, { useContext } from "react";
import CompanyDetails from "../context/companyDetailsContext";
import PageTitke from "./PageTitke";
import useForm from "./validate/useForm";
import validate from "./validate/LoginFormValidationRules";
import swal from "sweetalert";
import axios from "axios";

export default function ContactPage() {
  const companyDetails = useContext(CompanyDetails);

  // let navigate = useNavigate();

  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate
  );

  const name1 = values.name;
  const email1 = values.email;
  const service1 = values.service;
  const message1 = values.message;
  const phone1 = values.phone;

  function login() {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(
        "https://thesparksolutionz.com/contact_api.php",
        {
          name: name1,
          email: email1,
          service: service1,
          message: message1,
          phone: phone1,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          swal("Submitted!", "Email Sent Successfully", "success");
        } else {
          swal("Error!", "Some thing went wronge!", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        swal("Error!", "Some thing went wronge!", "error");
      });

    // navigate("/");
  }

  return (
    <>
      <PageTitke tiltext="Contact Us" />
      <div className="wpo-contact-pg-section">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="office-info">
                <div className="row justify-content-center">
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="las la-map-marker addr"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Islamabad Branch</h2>
                        <p>
                          <a>{companyDetails.comdetail.textdtl.address2}</a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="las la-map-marker addr"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Lahore Branch</h2>
                        <p>
                          <a>{companyDetails.comdetail.textdtl.address3}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="las la-map-marker addr"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Sargodha Branch</h2>
                        <p>
                          <a
                          // href={companyDetails.comdetail.linkdtl.address}
                          // target="blank"
                          >
                            {companyDetails.comdetail.textdtl.address}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12 mt-lg-4">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="las la-paper-plane email"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Email Us</h2>
                        <p>
                          <a href={companyDetails.comdetail.linkdtl.email}>
                            {companyDetails.comdetail.textdtl.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12 mt-lg-4">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="las la-phone-volume phone"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Call Now</h2>
                        <p>
                          <a href={companyDetails.comdetail.linkdtl.phone}>
                            {companyDetails.comdetail.textdtl.phone}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="wpo-contact-title">
                <h2>Have Any Question?</h2>
                <p>
                  We appreciate your interest in our custom software development
                  services.
                </p>
              </div>

              <div className="wpo-contact-form-area">
                <form
                  method="post"
                  className="contact-validation-active"
                  id="contact-form-main"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={`form-control ${errors.name && "is-danger"}`}
                        name="name"
                        id="name"
                        onChange={handleChange}
                        value={values.name || ""}
                        placeholder="Your Name*"
                        required
                      />
                      {errors.name && (
                        <p className="help is-danger">{errors.name}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email && "is-danger"
                        }`}
                        name="email"
                        id="email"
                        onChange={handleChange}
                        placeholder="Your Email*"
                        value={values.email || ""}
                        required
                      />
                      {errors.email && (
                        <p className="help is-danger">{errors.email}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <input
                        type="test"
                        className={`form-control ${
                          errors.phone && "is-danger"
                        }`}
                        name="phone"
                        id="phone"
                        placeholder="Your Phone*"
                        onChange={handleChange}
                        value={values.phone || ""}
                        required
                      />
                      {errors.phone && (
                        <p className="help is-danger">{errors.phone}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <select
                        name="service"
                        className={`form-control ${
                          errors.service && "is-danger"
                        }`}
                        onChange={handleChange}
                        value={values.service || ""}
                        required
                      >
                        <option disabled="disabled" value="">
                          Select Services
                        </option>
                        <option value="Web Development">Web Development</option>
                        <option value="App Development">App Development</option>
                        <option value="Graphic Designing">
                          Graphic Designing
                        </option>
                        <option value="SEO Optimization">
                          SEO Optimization
                        </option>
                        <option value="Creative Elements">
                          Creative Elements
                        </option>
                        <option value="Dedicated Support">
                          Dedicated Support
                        </option>
                        <option value="Others">Others</option>
                      </select>
                      {errors.service && (
                        <p className="help is-danger">{errors.service}</p>
                      )}
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className={`form-control ${
                          errors.message && "is-danger"
                        }`}
                        name="message"
                        id="note"
                        placeholder="Message..."
                        onChange={handleChange}
                        value={values.message || ""}
                        required
                      ></textarea>
                      {errors.message && (
                        <p className="help is-danger">{errors.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="submit-area">
                    <button
                      type="submit"
                      name="sendMessage"
                      className="theme-btn-2"
                    >
                      Get in Touch
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="wpo-contact-map-section">
        <h2 className="hidden">Contact map</h2>
        <div className="wpo-contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.088554148056!2d72.6805638155486!3d32.066853527160795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392177b8390f46cb%3A0xe6434929405fb81a!2sSparkSolutionz!5e0!3m2!1sen!2s!4v1655895113000!5m2!1sen!2s"
            width=""
            height=""
            title="map"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
}
