import React from 'react'
import About from './home_Page/About';
import Completion from './home_Page/Completion';
import Contact from './home_Page/Contact';
import HomeBanner from './home_Page/HomeBanner';
import Portfolio from './home_Page/Portfolio';
import Services from './home_Page/Services';
import Sponsers from './home_Page/Sponsers';
import Team from './home_Page/Team';
import Testemonials from './home_Page/Testemonials';

export default function HomePage() {


const complete = {
  roundcount:{
    cirfirst: "500+",
    cirsec: "1000",
    cirthr: "200"
  },
  texthead: {
    optf: "Satisfied customers",
    opts: "Project completed",
    optt: "CMS installations",
  }

}

  return (
    <>
    <HomeBanner />
    <Services />
    <Completion data={complete.texthead} circont={complete.roundcount}/>
    <Team />
    <Portfolio />
    <Testemonials />
    <Sponsers />
    <About />
    <Contact />
    </>
  )
}
